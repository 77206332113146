<template>
  <div class="row">
    <div class="col-lg-8">
      <card>
        <template slot="header">
          <h5 class="card-title">Data Referensi</h5>
        </template>
        <template slot="default">
          <div>
            <div class="mb-4" v-for="(reference, index) in references" :key="index">
              <h6><router-link :to="reference.path" :class="reference.titleClass">{{reference.title}}</router-link></h6>
              <p>{{reference.description}}</p>
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
  import { Card } from '../../UIComponents'
  import * as urlConstant from '../../../constants/urlConstant'

  export default {
    components: {
      Card
    },
    data () {
      return {
        references: [
          {
            title: 'Set Kurs Emas',
            description: 'Mengeset harga acuan untuk produk yang berbahan dasar emas.',
            path: urlConstant.WEB_URL_REFERENCES_GOLD_PRICES_NEW
          },
          {
            title: 'Histori Kurs Emas',
            description: 'Menampilkan histori harian kurs emas.',
            path: urlConstant.WEB_URL_REFERENCES_GOLD_PRICES
          },
          {
            title: 'Kategori Produk',
            description: 'Daftar kategori produk untuk memudahkan kamu mengelompokkan produk-produkmu. (Fitur belum tersedia)',
            path: urlConstant.WEB_URL_REFERENCES,
            titleClass: 'text-muted'
          },
          {
            title: 'Bahan Produk',
            description: 'Daftar bahan produk untuk menunjukkan dari material apa produk kamu dibuat. (Fitur belum tersedia)',
            path: urlConstant.WEB_URL_REFERENCES,
            titleClass: 'text-muted'
          },
          {
            title: 'Satuan Produk',
            description: 'Daftar satuan produk untuk menghitung jumlah dari produk kamu. (Fitur belum tersedia)',
            path: urlConstant.WEB_URL_REFERENCES,
            titleClass: 'text-muted'
          },
        ]
      }
    }
  }
</script>
<style scoped>
</style>
