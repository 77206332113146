<template>
  <card>
    <template slot="header">
      <h5 class="card-title">Histori Kurs Emas</h5>
    </template>
    <template slot="default">
      <div class="row">

        <div class="col-12">
          <div class="d-flex flex-wrap justify-content-md-end justify-content-center">
            <p-button outline round @click="goToPrevPage">Kembali</p-button>
            <p-button round type="primary" @click="newPrice">
              <i slot="label" class="nc-icon nc-simple-add"></i>
              &nbsp;Set Kurs Emas
            </p-button>
          </div>
        </div>

        <div class="col-12 mb-3">
          <el-table :data="goldPrices" 
                    header-row-class-name="text-primary" 
                    v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
            <el-table-column label="Tanggal" width="120">
              <template slot-scope="scope">
                <div>{{scope.row.postedDate | dateFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Harga" min-width="180" align="right">
              <template slot-scope="scope">
                <div>{{scope.row.price | currencyFormat}}</div>
              </template>
            </el-table-column>
            <el-table-column label="Waktu Posting" width="200">
              <template slot-scope="scope">
                <div>{{scope.row.createdAt | dateTimeFormat}}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-12" v-if="pagination.totalCount > 0">
          <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <p class="text-muted text-center small">
              Menampilkan urutan {{paginationFrom}}
              <span v-if="paginationFrom < paginationTo">sampai {{paginationTo}}</span>
              dari total {{pagination.totalCount}} data
            </p>
            <div class="d-flex justify-content-center">
              <p-pagination v-model="pagination.pageNumber"
                            :per-page="pagination.pageSize"
                            :total="pagination.totalCount"
                            v-on:input="changePage">
              </p-pagination>
            </div>
          </div>
        </div>

      </div>
    </template>
  </card>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import { Card, Pagination } from '../../UIComponents'
  import { Table, TableColumn, Loading } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import filterHelper from '../../../helpers/filterHelper'
  import * as routerConstant from '../../../constants/routerConstant'

  Vue.use(Loading)

  const defaultPageNumber = 1
  const defaultPageSize = 10
  const pageSizeOptions = [10, 25, 50, 100]

  export default {
    components: {
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Pagination.name]: Pagination,
    },
    data () {
      return {
        isLoading: false,
        hasPrevRoute: false,
        isNavigatingBack: false,
        goldPrices: [],
        pagination: {
          pageNumber: defaultPageNumber,
          pageSize: defaultPageSize,
          totalCount: 0,
          pageSizeOptions: pageSizeOptions
        },
      }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        const routeChanged = this.setDefaultRouteQuery()

        if (routeChanged) {
          return
        }
        
        this.setPagination()
        this.fetchData()
      },
      fetchData () {
        this.isLoading = true

        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber
        }
        
        this.axios.get(urlConstant.API_URL_GOLD_PRICES, { params: query })
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isLoading = false
              return
            }
            
            this.goldPrices = response.data.goldPrices
            this.pagination.totalCount = response.data.totalCount

            this.isLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isLoading = false
          })
      },
      setDefaultRouteQuery () {
        let isChanged = false
        let query = _.cloneDeep(this.$route.query)

        if (!query.pageSize) {
          query.pageSize = defaultPageSize
          isChanged = true
        }

        if (!query.pageNumber) {
          query.pageNumber = defaultPageNumber
          isChanged = true
        }
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      setPagination () {
        if (this.$route.query.pageSize) {
          this.pagination.pageSize = parseInt(this.$route.query.pageSize)
        }

        if (this.$route.query.pageNumber) {
          this.pagination.pageNumber = parseInt(this.$route.query.pageNumber)
        }
      },
      changePage () {
        this.applyRoute()
      },
      applyRoute () {
        const query = {
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber
        }
        
        this.$router.push({ path: this.$route.path, query: query })
      },
      newPrice () {
        this.$router.push(urlConstant.WEB_URL_REFERENCES_GOLD_PRICES_NEW)
      },
      goToPrevPage () {
        if (this.hasPrevRoute) {
          this.isNavigatingBack = true
          this.$router.go(-1)
        }
        else {
          this.$router.replace(urlConstant.WEB_URL_REFERENCES)
        }
      },
    },
    computed: {
      paginationFrom () {
        return ((this.pagination.pageNumber - 1) * this.pagination.pageSize) + 1
      },
      paginationTo () {
        const result = this.pagination.pageNumber * this.pagination.pageSize
        return result < this.pagination.totalCount ? result : this.pagination.totalCount
      }
    },
    filters: {
      ...filterHelper.spread
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
    beforeRouteEnter (to, from, next) {
      next(x => x.hasPrevRoute = !!from.name && !from.name.includes(to.name));
    },
    beforeRouteUpdate (to, from, next) {
      if (this.hasPrevRoute && this.isNavigatingBack && to.name === from.name) {
        this.$router.go(-1)
        next(new Error(routerConstant.REDIRECT_TO_SELF))
        return
      }

      next()
    },
  }
</script>
<style scoped lang="scss">
</style>
