<template>
  <div>

    <!-- Stat cards -->
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-sm-6" v-for="stats in filteredStatsCards" v-bind:key="stats.title">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.value"
                    :title-class="stats.valueClass"
                    :small-title-resize-options="{ratio: 1.3, minFontSize: 8, maxFontSize: 16}"
                    :title-resize-options="{ratio: 0.8, minFontSize: 8, maxFontSize: 28}"
                    v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
          <div v-resize-text="{ratio: 1.6, minFontSize: 8, maxFontSize: 14}" class="stats" slot="footer">
            <i :class="stats.footerIcon"></i>
            {{stats.footerText}}
          </div>
        </stats-card>
      </div>
    </div>

  </div>
</template>
<script>
  import Vue from 'vue'
  import ResizeText from 'vue-resize-text'
  import moment from 'moment'
  import StatsCard from '../../../UIComponents/Cards/StatsCard'
  import errorHandlerHelper from '../../../../helpers/errorHandlerHelper'
  import * as urlConstant from '../../../../constants/urlConstant'
  import { Loading } from 'element-ui'
  import filterHelper from '../../../../helpers/filterHelper'
  import { GOLD_PRICE_FETCH } from '../../../../store/actions/goldPrice'
  import * as userRoleEnum from '../../../../enums/userRoleEnum'

  Vue.use(Loading)

  const statCardKeys = {
    stock: 'stock',
    newStock: 'newStock',
    goldPrice: 'goldPrice',
    salesCount: 'salesCount',
    revenue: 'revenue',
    goldStock: 'goldStock',
    newGoldStock: 'newGoldStock',
    goldSold: 'goldSold',
    purchasesCount: 'purchasesCount',
    expenses: 'expenses',
  }

  export default {
    components: {
      StatsCard
    },
    directives: {
      ResizeText
    },
    data () {
      return {
        isDashboardFetching: false,
        isGoldPriceFetching: false,
        statsCards: [
          {
            key: statCardKeys.salesCount,
            type: 'primary',
            icon: 'nc-icon nc-cart-simple',
            title: 'Penjualan Hari Ini',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-tag-content'
          },
          {
            key: statCardKeys.revenue,
            type: 'success',
            icon: 'nc-icon nc-money-coins',
            title: 'Omzet Hari Ini',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-money-coins',
            roles: userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE
          },
          {
            key: statCardKeys.purchasesCount,
            type: 'warning',
            icon: 'nc-icon nc-cart-simple',
            title: 'Pembelian Hari Ini',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-tag-content'
          },
          {
            key: statCardKeys.expenses,
            type: 'danger',
            icon: 'nc-icon nc-money-coins',
            title: 'Total Pembelian Hari Ini',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-cart-simple',
            roles: userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE
          },
          {
            key: statCardKeys.goldSold,
            type: 'warning',
            icon: 'fa fa-cube',
            title: 'Emas 24k Terjual Hari Ini',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-tag-content',
            roles: userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE
          },
          {
            key: statCardKeys.productTransfers,
            type: 'info',
            icon: 'nc-icon nc-delivery-fast',
            title: 'Trf-In Diterima Hari Ini',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-send'
          },
          {
            key: statCardKeys.newStock,
            type: 'success',
            icon: 'nc-icon nc-box',
            title: 'Produk Trf-In Hari Ini',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-tag-content',
            roles: userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE
          },
          {
            key: statCardKeys.newGoldStock,
            type: 'warning',
            icon: 'fa fa-cubes',
            title: 'Emas 24k Trf-In Hari Ini',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-tag-content',
            roles: userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE
          },
          {
            key: statCardKeys.stock,
            type: 'danger',
            icon: 'nc-icon nc-box',
            title: 'Total Stok',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-tag-content',
            roles: userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE
          },
          {
            key: statCardKeys.goldStock,
            type: 'warning',
            icon: 'fa fa-cubes',
            title: 'Total Stok Emas 24k',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-tag-content',
            roles: userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE
          },
          {
            key: statCardKeys.goldPrice,
            type: 'warning',
            icon: 'fa fa-bandcamp',
            title: 'Kurs Emas',
            value: '-',
            footerText: 'Memuat...',
            footerIcon: 'nc-icon nc-calendar-60'
          },
        ]
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      fetchData () {
        this.isGoldPriceFetching = true

        this.$store
          .dispatch(GOLD_PRICE_FETCH)
          .then((goldPrice) => {
            // Update gold price stats card
            let goldPriceSc = this.statsCards.find(sc => sc.key === statCardKeys.goldPrice)
            goldPriceSc.value = goldPrice && goldPrice.price ? 
              filterHelper.currencyFormat(goldPrice.price || 0) : '-'
            goldPriceSc.footerText = goldPrice && goldPrice.postedDate ? 
              `Per tanggal ${filterHelper.dateFormat(goldPrice.postedDate)}` : 'Data tidak tersedia'

            this.isGoldPriceFetching = false
          })
          .catch((error) => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isGoldPriceFetching = false
          })
        
        var params = { requestDate: moment().format() }

        this.isDashboardFetching = true
        this.axios.get(urlConstant.API_URL_DASHBOARD, { params: params })
          .then(response => {
            if (!response.data.isSuccess) {
              errorHandlerHelper.handleGeneralApiError()
              this.isDashboardFetching = false
              return
            }

            const data = response.data
            
            // Update total stock count card
            if (data.productSummary) {
              let productSc = this.statsCards.find(sc => sc.key === statCardKeys.stock)
              productSc.value = `${filterHelper.numberFormat(data.productSummary.totalStocks)} unit`
              productSc.footerText = `Dari ${filterHelper.numberFormat(data.productSummary.totalProducts)} jenis produk`
            }

            // Update total new stock count card
            if (data.dateRangeProductTransferSummary) {
              let newProductSc = this.statsCards.find(sc => sc.key === statCardKeys.newStock)
              newProductSc.value = `${filterHelper.numberFormat(data.dateRangeProductTransferSummary.totalStocks)} unit`
              newProductSc.footerText = `Dari ${filterHelper.numberFormat(data.dateRangeProductTransferSummary.totalProducts)} jenis produk`
            }

            // Update total gold stock
            if (data.productSummary) {
              let goldStockSc = this.statsCards.find(sc => sc.key === statCardKeys.goldStock)
              goldStockSc.value = `${filterHelper.numberWithFractionFormat(data.productSummary.totalGoldStocksWeight || 0)} gr`
              goldStockSc.footerText = `Dari ${filterHelper.numberFormat(data.productSummary.totalGoldStocks)} jenis produk`
            }

            // Update total new gold stock
            if (data.dateRangeProductTransferSummary) {
              let newGoldStockSc = this.statsCards.find(sc => sc.key === statCardKeys.newGoldStock)
              newGoldStockSc.value = `${filterHelper.numberWithFractionFormat(data.dateRangeProductTransferSummary.totalGoldStocksWeight || 0)} gr`
              newGoldStockSc.footerText = `Dari ${filterHelper.numberFormat(data.dateRangeProductTransferSummary.totalGoldStocks)} jenis produk`
            }

            // Update total sales count card
            if (data.sellInvoiceSummary) {
              let salesSc = this.statsCards.find(sc => sc.key === statCardKeys.salesCount)
              salesSc.value = filterHelper.numberFormat(data.sellInvoiceSummary.paidInvoicesTotalCount || 0)
              salesSc.footerText = `${filterHelper.numberFormat(data.sellInvoiceSummary.paidInvoicesTotalItemCount || 0)} unit terjual`
            }

            // Update revenue card
            if (data.sellInvoiceSummary) {
              let revenueSc = this.statsCards.find(sc => sc.key === statCardKeys.revenue)
              revenueSc.value = filterHelper.currencyFormat(data.sellInvoiceSummary.paidInvoicesTotalAmount || 0)
              revenueSc.footerText = `L. Pnj. Kotor: ${filterHelper.currencyFormat(data.sellInvoiceSummary.paidInvoicesTotalGrossProfitAmount || 0)}`
            }

            // Update gold sold card
            if (data.sellInvoiceSummary) {
              let goldSoldSc = this.statsCards.find(sc => sc.key === statCardKeys.goldSold)
              goldSoldSc.value = `${filterHelper.numberWithFractionFormat(data.sellInvoiceSummary.goldSoldWeight || 0)} gr`
              goldSoldSc.footerText = `${filterHelper.numberFormat(data.sellInvoiceSummary.goldItemSoldCount || 0)} unit emas terjual`
            }
            
            // Update total purchases count card
            if (data.purchaseInvoiceSummary) {
              let purchasesSc = this.statsCards.find(sc => sc.key === statCardKeys.purchasesCount)
              purchasesSc.value = filterHelper.numberFormat(data.purchaseInvoiceSummary.paidPurchasesTotalCount || 0)
              purchasesSc.footerText = `${filterHelper.numberFormat(data.purchaseInvoiceSummary.paidPurchasesTotalItemCount || 0)} unit dibeli`
            }

            // Update expenses card
            if (data.purchaseInvoiceSummary) {
              let expensesSC = this.statsCards.find(sc => sc.key === statCardKeys.expenses)
              expensesSC.value = filterHelper.currencyFormat(data.purchaseInvoiceSummary.paidPurchasesTotalAmount || 0)
              expensesSC.footerText = `Dari ${filterHelper.numberFormat(data.purchaseInvoiceSummary.paidPurchasesTotalCount || 0)} inv. pembelian`
            }

            // Update product transfers card
            if (data.dateRangeProductTransferSummary) {
              let productTransfersSc = this.statsCards.find(sc => sc.key === statCardKeys.productTransfers)
              productTransfersSc.value = filterHelper.numberFormat(data.dateRangeProductTransferSummary.totalReceivedTransfers || 0)
              productTransfersSc.footerText = `${filterHelper.numberFormat(data.dateRangeProductTransferSummary.totalInProgressTransfers)} pending transfer`
            }

            this.isDashboardFetching = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isDashboardFetching = false
          })
      }
    },
    computed: {
      isLoading () {
        return this.isDashboardFetching || this.isGoldPriceFetching
      },
      filteredStatsCards () {
        const userRole = this.$store.getters.getUserRole

        const result = this.statsCards.filter(sl => 
          !sl.roles || 
          !sl.roles.length ||
          sl.roles.includes(userRole))

        return result
      }
    },
    filters: {
      ...filterHelper.spread
    },
  }
</script>
<style scoped>
</style>
