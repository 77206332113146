<template>
  <div>
    <card>
      <template slot="header">
        <h5 class="card-title">Pengaturan</h5>
      </template>
      <template slot="default">
        <p>Fitur belum tersedia</p>
      </template>
    </card>
  </div>
</template>
<script>
  import { Card } from '../../UIComponents'

  export default {
    components: {
      Card
    },
    data () {
      return {}
    },
    methods: {
    }
  }
</script>
<style scoped>
</style>
