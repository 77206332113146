import { render, staticRenderFns } from "./GoldPrices.vue?vue&type=template&id=054e6a02&scoped=true&"
import script from "./GoldPrices.vue?vue&type=script&lang=js&"
export * from "./GoldPrices.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "054e6a02",
  null
  
)

export default component.exports